<template>
	
	<div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		mounted() {
		},
		methods: {
		},
		
		/* 计算属性 */
		computed: {
		},
	}
</script>

<style>
	.cell-item img{
		width: 100%;
	}
	.vue-waterfall-column{
		width: 46%;
	}
</style>
